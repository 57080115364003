import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../logo/logo';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <header>
      <nav className="bg-primary border-gray-200">
        <div className="flex flex-wrap items-center justify-between mx-auto px-4">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              navigate('/');
            }}
          >
            <Logo sx={{ mr: 4 }} />
          </div>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-secondary rounded-lg xl:hidden hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 "
            aria-controls="navbar-default"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isMenuOpen ? 'block' : 'hidden'
            } w-full xl:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-semibold text-lg flex flex-col p-4 md:flex-row md:space-x-8 rtl:space-x-reverse ">
              <li>
                <Link
                  to="/"
                  className="block py-2 px-3 text-secondary rounded-md md:bg-transparent md:text-secondary hover:bg-secondary hover:text-primary"
                  aria-current="page"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/#about-us"
                  className="block py-2 px-3 text-secondary rounded-md md:bg-transparent md:text-secondary hover:bg-secondary  hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/#services"
                  className="block py-2 px-3 text-secondary rounded-md md:bg-transparent md:text-secondary hover:bg-secondary  hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/#gallery"
                  className="block py-2 px-3 text-secondary rounded-md md:bg-transparent md:text-secondary hover:bg-secondary  hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/#testimonials"
                  className="block py-2 px-3 text-secondary rounded-md md:bg-transparent md:text-secondary hover:bg-secondary  hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    navigate('/contact-us');
                  }}
                  className="block w-full text-left md:text-center py-2 px-3 text-secondary md:text-primary md:bg-secondary rounded-lg hover:bg-secondary  hover:text-primary"
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
