import React from 'react';

function ServiceCard({ image, category, path }) {
  return (
    <div
      className="bg-white shadow-lg rounded-lg overflow-hidden m-4 w-11/12 md:w-96 md:h-72 hover:shadow-xl cursor-pointer"
      data-aos="flip-up"
      data-aos-delay="400"
      onClick={() => (window.location.href = `${path.toLowerCase()}`)}
    >
      <img
        src={image}
        alt={path}
        className="w-full h-52 object-cover"
        loading='lazy'
        effect='opacity'
        lazy='true'
      />
      {/* <LazyLoadImage
        src={image}
        alt={path}
        className="w-full h-52 object-cover"
        effect="opacity"
      /> */}
      <div className="p-4 text-center">
        <p className="text-2xl text-yellow-600 font-semibold font-galdeano uppercase">
          {category}
        </p>
      </div>
    </div>
  );
}

export default ServiceCard;
