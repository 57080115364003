import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import { Footer } from '../components/footer';
import Loading from '../components/Loading';
import HairService from '../components/our-services/HairService';
import SkinService from '../components/our-services/SkinService';
import NailService from '../components/our-services/NailService';
import ComboOffers from '../components/our-services/ComboOffers';
import MakeupServices from '../components/our-services/MakeupServices';

export default function ServicePage() {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);

    const images = document.querySelectorAll('img');
    const components = Array.from(images);
    console.log(components);

    let loaded = 0;
    components.forEach((component) => {
      component.onload = () => {
        loaded += 1;
        if (loaded === components.length) {
          handleLoad();
        }
      };
    });

    const timeoutId = setTimeout(handleLoad, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = (path) =>
    pathname === path ? 'bg-primary text-white' : 'bg-secondary text-white';

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <div className="flex flex-col">
        <div className="w-full p-4">
          <h2 className="text-center text-3xl lg:text-5xl font-bold font-gwendolyn text-primary mb-8">
            What we offer
          </h2>
          {isMobile ? (
            <select
              className="block mb-2 py-2 px-4 text-left bg-secondary text-white rounded-lg hover:bg-primary w-full"
              value={pathname}
              onChange={(e) => (window.location.href = e.target.value)}
            >
              <option value="/services/makeup">Make Up</option>
              <option value="/services/skin">Skin</option>
              <option value="/services/hair">Hair</option>
              <option value="/services/nails">Legs & Nails</option>
              <option value="/services/combo">Combo Offers</option>
            </select>
          ) : (
            <nav className="flex flex-col md:flex-row md:gap-10 justify-center">
              <a
                href="/services/makeup"
                className={`block mb-2 py-2 px-4 text-left rounded-full hover:bg-primary ${isActive(
                  '/services/makeup'
                )}`}
              >
                Make Up
              </a>
              <a
                href="/services/skin"
                className={`block mb-2 py-2 px-4 text-left rounded-full hover:bg-primary ${isActive(
                  '/services/skin'
                )}`}
              >
                Skin
              </a>
              <a
                href="/services/hair"
                className={`block mb-2 py-2 px-4 text-left rounded-full hover:bg-primary ${isActive(
                  '/services/hair'
                )}`}
              >
                Hair
              </a>
              <a
                href="/services/nails"
                className={`block mb-2 py-2 px-4 text-left rounded-full hover:bg-primary ${isActive(
                  '/services/nails'
                )}`}
              >
                Legs & Nails
              </a>
              <a
                href="/services/combo"
                className={`block mb-2 py-2 px-4 text-left rounded-full hover:bg-primary ${isActive(
                  '/services/combo'
                )}`}
              >
                Combo Offers
              </a>
            </nav>
          )}
        </div>
        <div className="w-full p-4">
          {pathname === '/services/hair' && <HairService />}
          {pathname === '/services/makeup' && <MakeupServices />}
          {pathname === '/services/skin' && <SkinService />}
          {pathname === '/services/nails' && <NailService />}
          {pathname === '/services/combo' && <ComboOffers />}
        </div>
      </div>
      <Footer />
    </div>
  );
}
