import React, { useState } from 'react';

const hairContentData = [
  {
    title: 'Hair Cuts',
    description: 'Description for hair service 1.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508600/Nivez_Bridal/Services/Hair/hair-cuts_phcbdg.jpg',
    subServices: [
      {
        title: 'Hair Trim',
      },
      {
        title: 'U Cut',
      },
      {
        title: 'V Cut',
      },
      {
        title: 'Child Cut',
      },
      {
        title: 'Layer Cut',
      },
      {
        title: 'Butterfly Cut',
      },
      {
        title: 'Feather Cut',
      },
      {
        title: '*(Hair Wash Additional)',
      },
    ],
  },
  {
    title: 'Hair Setting',
    description: 'Description for hair service 2.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508594/Nivez_Bridal/Services/Hair/hair-setting_tyklld.jpg',
    subServices: [
      {
        title: 'One day Straightening',
      },
      {
        title: 'One day Curling',
      },
      {
        title: 'One day Blow dry setting',
      },
      {
        title: '*(Hair Wash Additional)',
      },
      {
        title: 'Hair Relaxing',
      },
      {
        title: 'Hair Straightening / Rebonding',
      },
      {
        title: 'Perming',
      },
      {
        title:
          '*(Enjoy the FREE Haircut for hair relaxing, Straightening & rebonding)',
      },
    ],
  },
  {
    title: 'Hair Colouring',
    description: 'Description for hair service 3.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508595/Nivez_Bridal/Services/Hair/hair-color_lgqafp.jpg',
    subServices: [
      {
        title: 'Fashion Colour',
      },
      {
        title: 'Gray Coverage',
      },
    ],
  },
  {
    title: 'Hair Treatments / Massges',
    description: 'Description for hair service 4.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508586/Nivez_Bridal/Services/Hair/hair-massage_jgxfc9.jpg',
    subServices: [
      {
        title: 'Keratin Treatment',
      },
      {
        title: 'Hair Spa',
      },
      {
        title: 'Coditioner Treatment',
      },
      {
        title: 'Dandruff Treatment',
      },
      {
        title:
          'Head Oil Massage (Pure Coconut Oil, Almond Oil, Olive, Vitamin E)',
      },
      {
        title: 'Head ,Neck, Shoulder & Hands Massage',
      },
      {
        title: 'Back & Legs Massage',
      },
      {
        title: 'Full Body Massage',
      },
    ],
  },
];

export default function HairService() {
  const [currentContent, setCurrentContent] = useState(hairContentData[0]);

  const handleButtonClick = (index) => {
    setCurrentContent(hairContentData[index]);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 p-4">
        <h2 className="text-3xl font-bold font-galdeano mb-4">Hair Services</h2>
        {hairContentData.map((content, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className="w-full mb-2 py-2 px-4 text-left bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            {content.title}
          </button>
        ))}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-5xl font-bold font-gwendolyn mb-4">
          {currentContent.title}
        </h2>
        {/* <p className="mb-4">{currentContent.description}</p> */}
        {currentContent.subServices && (
          <ul className="list-disc pl-4">
            {currentContent.subServices.map((subService, index) => (
              <li key={index}>
                <h3 className="text-lg font-bold mb-2">{subService.title}</h3>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full md:w-1/4 p-4">
        {currentContent.image && (
          <img
            src={currentContent.image}
            alt={currentContent.title}
            className="w-full h-auto rounded-lg"
            loading="lazy"
            lazy="true"
          />
        )}
      </div>
    </div>
  );
}
