import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Hero from '../components/hero/hero';
import AboutUs from '../components/about-us/about-us';
import OurServices from '../components/our-services/our-services';
import Gallery from '../components/gallery';
import { Testimonial } from '../components/testimonial';
import { Footer } from '../components/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/Loading';

function HomePage() {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const hash = useLocation().hash;
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 500);
    }
  }, [hash]);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);

    // Assume all components and images will have these onload events
    const images = document.querySelectorAll('img');
    const components = Array.from(images);

    let loaded = 0;
    components.forEach((component) => {
      component.onload = () => {
        loaded += 1;
        if (loaded === components.length) {
          handleLoad();
        }
      };
    });

    // Fallback in case images onload doesn't trigger
    const timeoutId = setTimeout(handleLoad, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleHashChange = () => {
    navigate('/');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.onscroll = () => {
    if (document.documentElement.scrollTop > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full">
      <Header />
      <Hero />
      <section id="about-us">
        <AboutUs />
      </section>
      <section id="services">
        <OurServices />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="testimonials">
        <Testimonial />
      </section>
      <Footer />
      {isVisible && (
        <div
          className="fixed bottom-4 right-4 bg-secondary text-white p-4 rounded-full cursor-pointer w-16 h-16 flex items-center justify-center"
          onClick={handleHashChange}
        >
          <FontAwesomeIcon icon={faAnglesUp} size="lg" />
        </div>
      )}
    </div>
  );
}

export default HomePage;
