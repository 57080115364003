import React from 'react';

export default function gallery_section() {
  const handleNavigate = () => {
    window.location.href = '/gallery';
  };
  
  return (
    <div className="py-8">
      <h2
        className="text-center text-3xl lg:text-5xl font-bold font-gwendolyn text-secondary md:mb-8"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Our Works
      </h2>
      <h3
        className="text-center text-4xl lg:text-7xl font-galdeano text-primary mt-4 lg:mt-8 md:mb-12"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        Gallery
      </h3>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <img
          src="https://res.cloudinary.com/dregpcfhh/image/upload/v1720157974/Nivez_Bridal/Homepage/gallery-collage_irvlc0.png"
          alt="gallery"
          className="w-full md:w-2/3 h-auto "
          loading="lazy"
          data-aos="zoom-in"
          data-aos-delay="400"
        />
        <div className="text-center mt-8">
          <button
            onClick={handleNavigate}
            className="bg-primary text-white font-semibold py-2 px-6 rounded-md shadow-md hover:bg-green-900 animate-bounce"
          >
            View Gallery &gt;&gt;
          </button>
        </div>
      </div>
    </div>
  );
}
