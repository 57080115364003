import React, { useState } from 'react';

const nailContentData = [
  {
    title: 'Pedicure / Menicure',
    description: 'Description for Pedicure / Menicure.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508940/Nivez_Bridal/Services/LegsAndNails/pedicure_uunoyn.jpg',
    subServices: [
      {
        title: 'Pedicure',
      },
      {
        title: 'Pedicure with gel Nail polish',
      },
      {
        title: 'Luxury Pedicure',
      },
      {
        title: 'Pedicure with bleach Additional',
      },
      {
        title: 'Menicure',
      },
      {
        title: 'Menicure with gel Nail polish',
      },

      {
        title: 'Menicure with bleach Additional',
      },
    ],
  },
  {
    title: 'Gel Nails',
    description: 'Description for Nail Art.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508939/Nivez_Bridal/Services/LegsAndNails/gel-nails_z6i4lj.jpg',
    subServices: [
      {
        title: 'Gel Nail Extension',
      },
      {
        title: 'Acrylic Nail',
      },
    ],
  },
  {
    title: 'Wax',
    description: 'Description for Wax.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508939/Nivez_Bridal/Services/LegsAndNails/wax_nwrs8e.jpg',
    subServices: [
      {
        title: 'Full Legs',
      },
      {
        title: 'Half Legs',
      },
      {
        title: 'Full Arms',
      },
      {
        title: 'Half Arms',
      },
      {
        title: 'Under Arms',
      },
      {
        title: 'Under Arm Whitening Treatment',
      },
    ],
  },
];

export default function NailService() {
  const [currentContent, setCurrentContent] = useState(nailContentData[0]);

  const handleButtonClick = (index) => {
    setCurrentContent(nailContentData[index]);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 p-4">
        <h2 className="text-3xl font-bold font-galdeano mb-4">Nail Services</h2>
        {nailContentData.map((content, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className="w-full mb-2 py-2 px-4 text-left bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            {content.title}
          </button>
        ))}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-5xl font-bold font-gwendolyn mb-4">
          {currentContent.title}
        </h2>
        {/* <p className="mb-4">{currentContent.description}</p> */}
        {currentContent.subServices && (
          <ul className="list-disc pl-4">
            {currentContent.subServices.map((subService, index) => (
              <li key={index}>
                <h3 className="text-lg font-bold mb-2">{subService.title}</h3>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full md:w-1/4 p-4">
        <img
          src={currentContent.image}
          alt={currentContent.title}
          className="w-full h-auto rounded-lg"
          loading='lazy'
          lazy='true'
        />
      </div>
    </div>
  );
}
