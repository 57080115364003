import React from 'react';

function Loading() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
      <div className="w-24 h-24 border-4 border-secondary border-dashed rounded-full animate-spin"></div>
    </div>
  );
}

export default Loading;
