import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Logo = forwardRef(({ disabledLink = false, sx, ...props }, ref) => {
  const logo = (
    <div className="flex items-center justify-center">
      <img
        src="/logo/logo_single.png"
        className="h-20 sm:h-20 md:h-20 lg:h-28 xl:h-28"
        alt="Logo"
        loading="lazy"
        {...props}
      />
      <div>
        <img
          src="logo/logo_name.png"
          alt="name"
          loading="lazy"
          style={{ height: 30 }}
        />
        <h1 className="text-2xl font-semibold hidden lg:block text-white">
          Bridal & Academy (Pvt) Ltd.
        </h1>
      </div>
    </div>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <a
      href="/"
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none',
        ...sx,
      }}
    >
      {logo}
    </a>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
