export const images = [
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013877/Nivez_Bridal/Gallery/nivez-1_uoppos.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013876/Nivez_Bridal/Gallery/nivez-2_hz88cd.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013871/Nivez_Bridal/Gallery/nivez-3_rggt2h.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013867/Nivez_Bridal/Gallery/nivez-4_zdi5hc.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013860/Nivez_Bridal/Gallery/nivez-5_ouqb3z.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013855/Nivez_Bridal/Gallery/nivez-6_wqeolv.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013851/Nivez_Bridal/Gallery/nivez-7_mejwia.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013846/Nivez_Bridal/Gallery/nivez-8_qjmfue.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013878/Nivez_Bridal/Gallery/nivez-9_gquo1d.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013840/Nivez_Bridal/Gallery/nivez-10_wm8jv4.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013837/Nivez_Bridal/Gallery/nivez-11_kfccu4.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013833/Nivez_Bridal/Gallery/nivez-12_xxtrc9.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013828/Nivez_Bridal/Gallery/nivez-13_wbgmmv.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013825/Nivez_Bridal/Gallery/nivez-14_kifypn.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013834/Nivez_Bridal/Gallery/nivez-15_st3vet.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013830/Nivez_Bridal/Gallery/nivez-16_uqvrbe.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013808/Nivez_Bridal/Gallery/nivez-17_cnbvjn.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013809/Nivez_Bridal/Gallery/nivez-18_w1nwpm.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013812/Nivez_Bridal/Gallery/nivez-19_imvnhl.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013817/Nivez_Bridal/Gallery/nivez-20_uf0qpw.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013807/Nivez_Bridal/Gallery/nivez-21_rswnp5.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013828/Nivez_Bridal/Gallery/nivez-22_oxoqyh.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013798/Nivez_Bridal/Gallery/nivez-23_atslsq.jpg',
    width: 320,
    height: 'auto',
  },
  {
    src: 'https://res.cloudinary.com/dregpcfhh/image/upload/v1720013802/Nivez_Bridal/Gallery/nivez-24_rn8vks.jpg',
    width: 320,
    height: 'auto',
  },
];