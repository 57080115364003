import React, { useState } from 'react';

const makupContentData = [
  {
    title: 'Make Up',
    description: 'Description for face service 1.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720777625/Nivez_Bridal/Services/MakeUp/makeup_01.png',
    subServices: [
      {
        title: 'Bridal Make Up',
      },
      {
        title: 'Registration Make Up',
      },
      {
        title: 'Reception Make Up',
      },
      {
        title: 'Birthday Make Up',
      },
      {
        title: 'Guest Make Up',
      },
    ],
  },
];

export default function MakeupServices() {
  const [currentContent, setCurrentContent] = useState(makupContentData[0]);

  const handleButtonClick = (index) => {
    setCurrentContent(makupContentData[index]);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 p-4">
        <h2 className="text-3xl font-bold font-galdeano mb-4">Combo Offers</h2>
        {makupContentData.map((content, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className="w-full mb-2 py-2 px-4 text-left bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            {content.title}
          </button>
        ))}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-5xl font-bold font-gwendolyn mb-4">
          {currentContent.title}
        </h2>
        {/* <p className="mb-4">{currentContent.description}</p> */}
        {currentContent.subServices && (
          <ul className="list-disc pl-4">
            {currentContent.subServices.map((subService, index) => (
              <li key={index}>
                <h3 className="text-lg font-bold mb-2">{subService.title}</h3>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full md:w-1/4 p-4">
        <img
          src={currentContent.image}
          alt={currentContent.title}
          className="w-full h-auto rounded-lg"
          loading="lazy"
          lazy="true"
        />
      </div>
    </div>
  );
}
