import React, { useEffect } from 'react';
import ServiceCard from '../service-card/service-card';
import AOS from 'aos';
import 'aos/dist/aos.css';

const services = [
  {
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720777625/Nivez_Bridal/Services/MakeUp/makeup_02.png',
    category: 'Make Up',
    path: '/services/makeup',
  },
  {
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720509029/Nivez_Bridal/Services/Skin/facials_twalpq.jpg',
    category: 'Skin Treatment',
    path: '/services/skin',
  },
  {
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508594/Nivez_Bridal/Services/Hair/hair-setting_tyklld.jpg',
    category: 'Hair Treatment',
    path: '/services/hair',
  },
  {
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508940/Nivez_Bridal/Services/LegsAndNails/pedicure_uunoyn.jpg',
    category: 'Legs & Nails Treatment',
    path: '/services/nails',
  },
  {
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720508519/Nivez_Bridal/Services/ComboOffers/combo-offer_yij9b7.png',
    category: 'Combo Offers',
    path: '/services/combo',
  },
];

function OurServices() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNavigate = () => {
    window.location.href = '/services/skin';
  };

  return (
    <div className="bg-primary py-16 px-4 relative">
      <h2
        className="text-center text-3xl lg:text-5xl font-bold font-gwendolyn text-secondary mb-8"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        What we offer
      </h2>
      <h3
        className="text-center text-4xl lg:text-7xl font-galdeano text-white mt-4 lg:mt-8 mb-12"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        Charming, bubbly, relaxing atmosphere
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            image={service.image}
            category={service.category}
            path={service.path}
          />
        ))}
      </div>
      <div className="text-center mt-8">
        <button
          onClick={handleNavigate}
          className="bg-white text-green-900 font-semibold py-2 px-6 rounded-md shadow-md hover:bg-gray-200 animate-bounce"
        >
          View more &gt;&gt;
        </button>
      </div>
    </div>
  );
}

export default OurServices;
