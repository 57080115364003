import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import { Footer } from '../components/footer';
import { Gallery } from 'react-grid-gallery';
import { images } from '../components/gallery-images';
import Loading from '../components/Loading';

export default function GalleryPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);

    const imageElements = images.map((image) => {
      const img = new Image();
      img.src = image.src;
      return img;
    });

    let loadedCount = 0;
    imageElements.forEach((img) => {
      img.onload = () => {
        loadedCount += 1;
        if (loadedCount === imageElements.length) {
          handleLoad();
        }
      };
    });

    // Fallback in case images onload doesn't trigger
    const timeoutId = setTimeout(handleLoad, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <div className="px-2 w-full">
        <Gallery
          images={images}
          rowHeight={500}
          enableImageSelection={false}
          margin={5}
          thumbnailStyle={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </div>
      <Footer />
    </div>
  );
}
