import React, { useEffect, useState } from 'react';
import Avatar from '../../assets/avatar.webp';

const testimonials = [
  {
    name: 'Kisobika Yogalingam',
    title: '',
    text: 'Hi Nive akka, You actually made my day very special. You as a makeup artist were incredibly skilled and attentive to my preferences. You not only made me look stunning also made me feel comfortable throughout the process just like my sister ❤️ I have to say about your good punctuality and quick responses. I was really impressed regarding your time management. Thank you once again for making my big day even more memorable ❤️❤️❤️',
    img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-1.png',
  },
  {
    name: 'Lathika Krishnapillai',
    title: '',
    text: "Thank you so much Akka for making my special day more perfect. You made me feel more fabulous ❤️ 😍 I specially asked for natural elegant look and you delivered it perfectly, I haven't expected this. It was truly impressive 🥳 Your friendly nature and exceptional listening skill make you the true professional 👏🏻 Thank you 😊 & I definitely would suggest you to all my friends ❤️ ❤️",
    img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-2.png',
  },
  {
    name: 'Priyanka Baskaran',
    title: '',
    text: "I had the privilege of witnessing my brother's wedding day, and I cannot express enough how impressed | was with the incredible makeup artistry provided by Nivethika acci. Her talent and expertise truly made my sister in law bridal look flawless and unforgettable. She took the time to listen to my sister in law's desires and preferences, ensuring that her vision was brought to life flawlessly. Her professionalism and dedication to her craft were remarkable. Nive acci showcased her exceptional skills by enhancing my sister in law's natural beauty while creating a look that was both glamorous and timeless. She flawlessly matched the makeup to my sister in law's skin tone and expertly accentuated her features, leaving her looking radiant and elegant. The attention to detail was truly impressive.",
    img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-3.png',
  },
  {
    name: 'Sobi Soman',
    title: '',
    text: 'I would highly recommended Nivez Bridal those who are looking for a very talented make up artist who you can truly trust on your special day to feel and look beautiful. Makeup and hair stayed gorgeous all day and you did an amazing job with my hair as i requested without changing the texture of it, I really loved it. I got a-lots of compliment on my special day, a very special THANK YOU Nivethiga Akka for doing make up for my wedding day and you did a phenomenal job 💛',
    img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-4.png',
  },
  {
    name: 'Santhiya Sri',
    title: '',
    text: "Hi Nivethi akka, Your such amazing and humble person and thank yo so much for being part of my special day and add more colors to it. I absolutely loved your makeup and hair style you made me look really stunning on my wedding day , it's so realistic and you made everything as I expected. I would highly recommend Nivez bridal and she is really friendly and always try to fulfill bride's requirements in good way , thank you so much for wonderful work and giving important to each and everything in detail.",
    img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-1.png',
  },
  // {
  //   name: 'Priyanthi Prathap',
  //   title: '',
  //   text: 'I recently had the privilege of experiencing the exquisite art of party makeup for my wedding, and it was truly an unforgettable experience. The makeup artist was incredibly skilled and attentive to every detail, ensuring that each aspect of my look was perfect.',
  //   img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-5.png',
  // },
  // {
  //   name: 'Rebecca Roy',
  //   title: '',
  //   text: 'I recently had the privilege of experiencing the exquisite art of reception makeup for my wedding, and it was truly an unforgettable experience. The makeup artist was incredibly skilled and attentive to every detail, ensuring that each aspect of my look was perfect.',
  //   img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-1.png',
  // },
  // {
  //   name: 'Sasi Sivamohan',
  //   title: '',
  //   text: 'I recently had the privilege of experiencing the exquisite art of reception makeup for my wedding, and it was truly an unforgettable experience. The makeup artist was incredibly skilled and attentive to every detail, ensuring that each aspect of my look was perfect.',
  //   img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-1.png',
  // },
  // {
  //   name: 'Ritha Ragavan',
  //   title: '',
  //   text: 'I recently had the privilege of experiencing the exquisite art of reception makeup for my wedding, and it was truly an unforgettable experience. The makeup artist was incredibly skilled and attentive to every detail, ensuring that each aspect of my look was perfect.',
  //   img: 'https://bzzipggojzhzuvqlaneg.supabase.co/storage/v1/object/public/gallery/g-1.png',
  // },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const { name, text } = testimonials[currentIndex];

  return (
    <div className="bg-primary min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-4xl mx-auto">
        <h2
          className="text-center text-4xl font-bold text-secondary mb-8 font-gwendolyn"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          What Customer Says
        </h2>
        <h2
          className="text-4xl lg:text-7xl font-galdeano text-white mt-4 lg:mt-8 text-center"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          Testimonials
        </h2>

        <div className="flex justify-between mt-8 gap-8 items-center">
          <div className="md:block hidden">
            <div
              onClick={handlePrev}
              className="flex items-center justify-center bg-secondary rounded-full w-12 h-12 cursor-pointer p-5"
            >
              <button className="text-white text-2xl">&#8249;</button>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col lg:flex-row items-center">
            <img
              src={Avatar}
              alt={name}
              className="rounded-full w-24 h-24 lg:w-32 lg:h-32 mb-4 lg:mb-0 lg:mr-6 object-cover"
            />

            <div>
              <h3 className="text-2xl font-semibold">{name}</h3>
              {/* <p className="text-gray-600">{title}</p> */}
              <p className="mt-4 text-gray-800 text-justify">{text}</p>
            </div>
          </div>
          <div className="md:block hidden">
            <div
              onClick={handleNext}
              className="flex items-center justify-center bg-secondary rounded-full w-12 h-12 cursor-pointer p-5"
            >
              <button className="text-white text-2xl">&#8250;</button>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <div className="flex space-x-2">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`block w-3 h-3 rounded-full ${
                  currentIndex === index ? 'bg-secondary' : 'bg-gray-300'
                }`}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
