import React, { useState } from 'react';

const skinContentData = [
  {
    title: 'Threading',
    description: 'Description for Threading.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720509030/Nivez_Bridal/Services/Skin/threading_dolgyb.jpg',
    subServices: [
      {
        title: 'Eyebrow Threading',
      },
      {
        title: 'Upper Lip Threading',
      },
      {
        title: 'Full Face Threading',
      },
    ],
  },
  {
    title: 'Clean Up',
    description: 'Description for face service 2.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720509027/Nivez_Bridal/Services/Skin/clean-up_xxc89g.jpg',
    subServices: [
      {
        title: 'Cleanup without massage',
      },
      {
        title: 'Cleanup with massage',
      },
      {
        title: 'Aroma Cleanup without massage',
      },
      {
        title: 'Aroma Cleanup with massage',
      },
      {
        title: 'Herbal Cleanup without massage',
      },
      {
        title: 'Herbal Cleanup with massage',
      },
    ],
  },
  {
    title: 'Facials',
    description: 'Description for face service 3.',
    image:
      'https://res.cloudinary.com/dregpcfhh/image/upload/v1720509029/Nivez_Bridal/Services/Skin/facials_twalpq.jpg',
    subServices: [
      {
        title: 'Normal Facial',
      },
      {
        title: 'Aroma Facial',
      },
      {
        title: 'Herbal Facial',
      },
      {
        title: 'Collagen Facial',
      },
      {
        title: 'Hydrating Facial',
      },
      {
        title: 'Vitamin C Facial',
      },
      {
        title: 'Whitening Facial',
      },
      {
        title: 'Pearl Facial',
      },
      {
        title: 'Aroma Bridal Glow Facial',
      },
      {
        title: 'Gold Facial',
      },
      {
        title: 'Pimple Treatment',
      },
      {
        title: 'Facials with Face Bleaching additional',
      },
      {
        title: 'Facials with Galvanic Treatement additional',
      },
    ],
  },
];

export default function SkinService() {
  const [currentContent, setCurrentContent] = useState(skinContentData[0]);

  const handleButtonClick = (index) => {
    setCurrentContent(skinContentData[index]);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/4 p-4">
        <h2 className="text-3xl font-bold font-galdeano mb-4">
          Skin Treatment
        </h2>
        {skinContentData.map((content, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className="w-full mb-2 py-2 px-4 text-left bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            {content.title}
          </button>
        ))}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-5xl font-bold font-gwendolyn mb-4">
          {currentContent.title}
        </h2>
        {/* <p className="mb-4">{currentContent.description}</p> */}
        {currentContent.subServices && (
          <ul className="list-disc pl-4">
            {currentContent.subServices.map((subService, index) => (
              <li key={index}>
                <h3 className="text-lg font-bold mb-2">{subService.title}</h3>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full md:w-1/4 p-4">
        <img
          src={currentContent.image}
          alt={currentContent.title}
          className="w-full h-auto rounded-lg"
          loading='lazy'
          lazy='true'
        />
      </div>
    </div>
  );
}
